


document.querySelector('body').classList.remove('body--active');

const header = document.querySelector('.header');
let currentDirection = 0;

window.addEventListener("scroll", () => {
  if (window.scrollY < currentDirection) {
    if (header.classList.contains('header--active')) header.classList.remove('header--active')
    currentDirection = window.scrollY
  } else if (window.scrollY > currentDirection && window.scrollY > 100) {
    if (!header.classList.contains('header--active')) header.classList.add('header--active');
    currentDirection = window.scrollY 
  }
});

const headerBurger = document.querySelector('.header__burger');
const headerBurgerItems = document.querySelectorAll('.header__burger span');
headerBurger.addEventListener('click', () => {
    document.querySelector('.header__bottom').classList.toggle('header__bottom--active');
    document.querySelector('body').classList.toggle('body--active');
    headerBurgerItems.forEach(item => {item.classList.toggle('active')})
})  