import Swiper from 'swiper/bundle'

const getSlider = () => {
	let slides = document.querySelectorAll('.contentslider-swiper .swiper-slide')
	let isBool = slides[swiper.realIndex + 1]?.querySelector('.contentslider__image')
	let closetSlider = slides[swiper.realIndex + 1]?.closest('.contentslider-swiper')



		if (document.documentElement.clientWidth > 992) {
			if (isBool === null) {
				closetSlider.querySelector('.swiper-pagination').style.left = '40px'
				// slides[swiper.realIndex + 1].style.paddingTop = '20px'
			} 
			else {closetSlider.querySelector('.swiper-pagination').style.left = '53%'}
		}
		else if (document.documentElement.clientWidth < 992 && document.documentElement.clientWidth > 550) {
			if (isBool === null) {
				closetSlider.querySelector('.swiper-pagination').style.top = '13px'
				closetSlider.querySelector('.contentslider__btns').style.top = '0px'
			} 
			else {
				closetSlider.querySelector('.swiper-pagination').style.top = '515px'
				closetSlider.querySelector('.contentslider__btns').style.top = '500px'
			}
		}
		else if (document.documentElement.clientWidth < 550 && document.documentElement.clientWidth > 450) {
			if (isBool === null) {
				closetSlider.querySelector('.swiper-pagination').style.top = '13px'
				closetSlider.querySelector('.contentslider__btns').style.top = '0px'
			} 
			else {
				closetSlider.querySelector('.swiper-pagination').style.top = '391px'
				closetSlider.querySelector('.contentslider__btns').style.top = '380px'
			}
		}
		else if (document.documentElement.clientWidth < 450) {
			if (isBool === null) {
				closetSlider.querySelector('.swiper-pagination').style.top = '13px'
				closetSlider.querySelector('.contentslider__btns').style.top = '0px'
			} else {
				closetSlider.querySelector('.swiper-pagination').style.top = '347px'
				closetSlider.querySelector('.contentslider__btns').style.top = '330px'
			}     
		}




	 
}

window.addEventListener("resize", (event) => {	
	getSlider()
});


const swiper = new Swiper('.contentslider-swiper', {
	loop: true,
	autoHeight: true,
	init: false,
	pagination: {
		el: '.swiper-pagination',
		type: 'fraction'
	},
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev'
	},
	on: {
		init: function () {
			getSlider()
		},

		realIndexChange: () => {
			getSlider()
		}
	}
})

swiper.init()





