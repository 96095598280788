import './stylesheets'
import '../Scss/includes.scss';
import * as bootstrap from 'bootstrap';

window.addEventListener('load', e => {
    const modules = import.meta.glob([
        './Content/**/*.js',
        './Extensions/**/*.js',
        
    ])

    for (const path in modules) {
        new Promise((_res, _rej) => modules[path]())
    }
})
