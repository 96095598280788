

const btnsShow = document.querySelectorAll('.textheader__btn');

btnsShow.forEach(btn => {
  btn.addEventListener('click', (e) => {

    let blockInfo = btn.closest('.textheader__info');
    let blockText = blockInfo.querySelector('.textheader__text');

    let dataId = blockText.getAttribute('data-language');
    console.log(dataId)
    let text = btn.innerHTML;
    if(dataId === '0' && text === 'weiterlesen') {btn.innerHTML = 'weniger anzeigen'}
    if(dataId === '0' && text === 'weniger anzeigen') {btn.innerHTML = 'weiterlesen'}

    if(dataId === '1' && text === 'show') {btn.innerHTML = 'hide'}
    if(dataId === '1' && text === 'hide') {btn.innerHTML = 'show'}

    let blockIcon = blockInfo.querySelector('.textheader__icon');
    blockText.classList.toggle('textheader__text--hidden')
    blockIcon.classList.toggle('textheader__icon--rotate')

  })
})
