

import Swiper from 'swiper/bundle';




    const swiper = new Swiper('.heroslider-swiper', {
        loop: true,
        pagination: {
          el: ".swiper-pagination",
          type: "fraction",
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }

        
    });





